let publicURL = process.env.REACT_APP_BACKEND_URL + "/backend/";
let baseURL = publicURL;
const API_URL = {
  publicURL: publicURL,
  baseURL: baseURL,
  
  attachments: baseURL + "attachments",
  activeTypes: baseURL + "activetypes",
  currencies: baseURL + "currencies",
  paymentMethods: baseURL + "paymentmethods",
  paymentTypes: baseURL + "paymenttypes",
  membershipTypes: baseURL + "membershiptypes",
 
  admins: baseURL + "admins",
  adminsLogin: baseURL + "admins/login",
  adminsExport: baseURL + "admins/export",
  dashboard: baseURL + "dashboard",
  payments: baseURL + "payments",
  contacts: baseURL + "contacts",
  contactsPrimaryAddress: baseURL + "contacts/primaryAddress",
  members: baseURL + "members",
  membersCountry: baseURL + "members/country",
  membersCategory: baseURL + "members/category", 
  membersContacts: baseURL + "members/contacts",
  reports: baseURL + "reports",
};

export { API_URL, baseURL };
