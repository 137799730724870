import React from 'react';
import { Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import App from '../screens/Rootpage/App';
import SignIn from '../screens/signin/signin';
import AuthCheck from '../screens/authCheck';
// import asyncComponent from '../helpers/AsyncFunc';

const PublicRoutes = ({ history, isLoggedIn }) => {
  return (
    <ConnectedRouter history={history}>
      <div>
        <Route
          path={''}
          component={AuthCheck}
        />
        <Route
          path={'/app'}
          component={App}
        />
        <Route
          exact
          path={'/login'}
          component={SignIn}
        />
      </div>
    </ConnectedRouter>
  );
};

export default connect(state => ({
  isLoggedIn: state.Auth.idToken !== null,
}))(PublicRoutes);
