import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Avatar } from 'antd';
import { Link } from "react-router-dom";

import authAction from '../../redux/auth/actions';
import themes from '../../settings/themes';


const { logout } = authAction;

class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  render() {
    let role = localStorage.getItem("role");
    let id = localStorage.getItem("admin_id");
    let url;
    if (role === "admin") {
      url = "/app/admin/viewAdmin/" + id;
    } else {
      url = this.props.url;
    }

    return (
      <div className="fomaImgWrapper">
        <Link to={url}>
          <Avatar style={{ color: '#FFF', backgroundColor: themes.themedefault.palette.primary[1] }}>{localStorage.getItem("first_name")?localStorage.getItem("first_name").charAt(0):"T"}</Avatar>
        </Link>
        {localStorage.getItem("first_name")}  {localStorage.getItem("last_name")}  
      </div>

    );
  }
}
export default connect(
  null,
  { logout }
)(TopbarUser);
