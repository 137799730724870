import React from 'react';
import { Link } from 'react-router-dom';
// import { siteConfig } from '../../settings';
//import logos from '../../image/EHS_LOGO.png';
//import logosWithText from "../../image/EHS_LOGO_TEXT.png";
export default ({ collapsed }) => {
  return (
    <div className="fomaLogoWrapper">
      {collapsed ? (
        <div>
          <h3 style={{ fontWeight: 800 }}>
            <Link to="/app">
        <img style={{ maxWidth: 60 }} src={require("./../../"+process.env.REACT_APP_LOGO)} alt="" />
            </Link>
          </h3>
        </div>
      ) : (
          <h3>
            <Link to="/app"><img style={{ maxHeight: 60 }} src={require("./../../"+process.env.REACT_APP_LOGO_WIDE)} alt="" /></Link>
          </h3>
        )}
    </div>
  );
};
