import React, { Component } from "react";
import { connect } from "react-redux";
import clone from "clone";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import options from "./options";
import Scrollbars from "../utility/customScrollBar.js";
import Menu from "../uielements/menu";
import SidebarWrapper from "./sidebar.style";
import appActions from "../../redux/app/actions";
import Logo from "../utility/logo";
import pound from "../../image/pound.png";
import reports from "../../image/reports.png";
import { isReadOnly, isSuperAdmin } from '../../helpers/utility';

const SubMenu = Menu.SubMenu;
const { Sider } = Layout;
const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed
} = appActions;
const stripTrailingSlash = str => {
  if (str.substr(-1) === "/") {
    return str.substr(0, str.length - 1);
  }
  return str;
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
  }
  handleClick(e) {
    this.props.changeCurrent([e.key]);
    if (this.props.app.view === "MobileView") {
      setTimeout(() => {
        this.props.toggleCollapsed();
        toggleOpenDrawer();
      }, 100);
    }
  }
  changeToogle = () => {
    console.log("e");
  };
  logout = () => {
    localStorage.clear();
    this.props.history.push("/login");
  };
  onOpenChange(newOpenKeys) {
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find(
      key => !(app.openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = app.openKeys.find(
      key => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  }
  getAncestorKeys = key => {
    const map = {
      sub3: ["sub2"]
    };
    return map[key] || [];
  };
  getMenuItem = ({ singleOption, submenuStyle, submenuColor }) => {
    const { key, label, leftIcon, children, disable } = singleOption;
    const url = stripTrailingSlash(this.props.url);
    let lbl = label;
    if (isSuperAdmin()) {
      lbl = label.replace("User", "User Management");
    }
    if (children) {
      return (
        <SubMenu
          key={key}
          onClick={() => {
            this.changeToogle();
          }}
          title={
            <span className="fomaMenuHolder" style={submenuColor}>
              {leftIcon === "image" ? (
                <img
                  style={{ maxWidth: 14 }}
                  src={key === "reports" ? reports : pound}
                  alt=""
                />
              ) : (
                <i className={leftIcon} />
              )}

              <span className="nav-text">{label}</span>
            </span>
          }
        >
          {children.map(child => {
            const linkTo = child.withoutDashboard
              ? `/${child.key}`
              : `${url}/${child.key}`;
            return (
              <Menu.Item
                style={submenuStyle}
                onClick={() => {
                  this.changeToogle();
                }}
                key={child.key}
              >
                <Link style={submenuColor} to={linkTo}>
                  {child.label}
                </Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
      );
    }
    return (
      label!=="User" || (label==="User" && !isReadOnly()) ?
      <Menu.Item key={key} disabled={disable}>
        <Link to={`${url}/${key}`}>
          <span className="fomaMenuHolder" style={submenuColor}>
            {leftIcon === "image" ? (
              <img
                style={{ maxWidth: 14 }}
                src={key === "reports" ? reports : pound}
                alt=""
              />
            ) : (
              <i className={leftIcon} />
            )}
            <span className="nav-text">{lbl}</span>
          </span>
        </Link>
      </Menu.Item> : ""
    );
  };
  render() {
    const { app, height } = this.props;
    const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
    const { openDrawer } = app;
    const mode = collapsed === true ? "vertical" : "inline";
    const onMouseEnter = event => {
      if (openDrawer === false) {
        // toggleOpenDrawer();
      }
      return;
    };
    const onMouseLeave = () => {
      if (openDrawer === true) {
        // toggleOpenDrawer();
      }
      return;
    };
    const styling = {
      backgroundColor: "#293855"
    };
    const submenuStyle = {
      backgroundColor: "rgba(0,0,0,0.3)",
      color: "#6d7487"
    };
    const submenuColor = {
      color: "#6d7487"
    };
    return (
      <SidebarWrapper>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          width={240}
          className="fomaSidebar"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={styling}
        >
          <Logo collapsed={collapsed} />
          <Scrollbars style={{ height: height - 70 }}>
            <Menu
              onClick={this.handleClick}
              theme="dark"
              className="fomaDashboardMenu"
              mode={mode}
              openKeys={collapsed ? [] : app.openKeys}
              selectedKeys={app.current}
              onOpenChange={this.onOpenChange}
            >
              {options.map(singleOption =>
                this.getMenuItem({ submenuStyle, submenuColor, singleOption })
              )}
              <SubMenu
                key="logout"
                className="logOut"
                title={
                  <span
                    className="fomaMenuHolder logout"
                    style={submenuColor}
                    onClick={this.logout}
                  >
                    <i className="ion-power" />
                    <span className="nav-text">logout</span>
                  </span>
                }
              ></SubMenu>
            </Menu>
          </Scrollbars>
        </Sider>
      </SidebarWrapper>
    );
  }
}

export default connect(
  state => ({
    app: state.App,
    height: state.App.height
  }),
  { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed }
)(Sidebar);
