import React from 'react';
import { Provider } from 'react-redux';
import { store, history } from './redux/store';
import PublicRoutes from './root-navigation/router';
import { ThemeProvider } from 'styled-components';
import themes from './settings/themes';
import { themeConfig } from './settings';
import DashAppHolder from './dashAppStyle';
import Boot from './redux/boot';
import GlobalStyles from './settings/globalStyles';

const DashApp = () => (

  <ThemeProvider theme={themes[themeConfig.theme]}>
    <DashAppHolder>
      <Provider store={store}>
        <PublicRoutes history={history} />
      </Provider>
      <GlobalStyles />
    </DashAppHolder>
  </ThemeProvider>
);
Boot()
  .then(() => DashApp())
  .catch(error => console.error(error));

export default DashApp;

