import styled from 'styled-components';
import { palette } from 'styled-theme';
import bgImage from '../../image/sign.jpg';
import WithDirection from '../../settings/withDirection';

const SignInStyleWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  display: flex;

  justify-content: flex-end;
  align-items: center;
  position: relative;
  background: url(${bgImage}) no-repeat;
  background-size: cover;
  background-position: calc(100% - 250px) 0px;
  .ant-checkbox-wrapper span{
    font-weight:600;
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    display: flex;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    z-index: 1;
    top: 0;
    left: ${props => (props['data-rtl'] === 'rtl' ? 'inherit' : '0')};
    right: ${props => (props['data-rtl'] === 'rtl' ? '0' : 'inherit')};
  }

  .fomaLoginContentWrapper {
    width: 500px;
    height: 100%;
    overflow-y: auto;
    z-index: 10;
    position: relative;
    background-color: #293855;
    align-items:center;
    display: flex;
  }

  .fomaLoginContent {

    display: flex;
    flex-direction: column;
    padding: 0 50px;
    position: relative;
    background-color: #293855;
    width: 100%;
    @media only screen and (max-width: 767px) {
      width: 100%;
    }

    .fomaLogoWrapper {
      width: 100%;
      display: flex;
      margin-bottom: 50px;
      justify-content: center;
      flex-shrink: 0;

      a {
        font-size:39px;
        font-weight: 300;
        line-height: 1;
        color: ${palette('secondary', 2)};
      }
    }

    .fomaSignInForm {
      width: 100%;
      display: flex;
      flex-shrink: 0;
      flex-direction: column;

      .fomaInputWrapper {
        margin-bottom: 15px;

        &:last-of-type {
          margin-bottom: 0;
        }

        input {
          &::-webkit-input-placeholder {
            color: ${palette('grayscale', 0)};
          }

          &:-moz-placeholder {
            color: ${palette('grayscale', 0)};
          }

          &::-moz-placeholder {
            color: ${palette('grayscale', 0)};
          }
          &:-ms-input-placeholder {
            color: ${palette('grayscale', 0)};
          }
        }
      }

      .fomaHelperText {
        font-size: 12px;
        font-weight: 400;
        line-height: 1.2;
        color: ${palette('grayscale', 1)};
        padding-left: ${props =>
    props['data-rtl'] === 'rtl' ? 'inherit' : '13px'};
        padding-right: ${props =>
    props['data-rtl'] === 'rtl' ? '13px' : 'inherit'};
        margin: 15px 0;
        position: relative;
        display: flex;
        align-items: center;

        &:before {
          content: '*';
          color: ${palette('error', 0)};
          padding-right: 3px;
          font-size: 14px;
          line-height: 1;
          position: absolute;
          top: 2px;
          left: ${props => (props['data-rtl'] === 'rtl' ? 'inherit' : '0')};
          right: ${props => (props['data-rtl'] === 'rtl' ? '0' : 'inherit')};
        }
      }

      .fomaHelperWrapper {
        margin-top: 35px;
        flex-direction: column;
      }



      .fomaForgotPass {
        font-size: 12px;
        color: ${palette('text', 3)};
        margin-bottom: 10px;
        font-weight:600;
        text-decoration: none;

        &:hover {
          color: ${palette('primary', 0)};
        }
      }

      button {
    padding: 12px 40px;
    height: auto;
    line-height: normal;
    margin: 0 auto;
      }
    }
  }
`;

export default WithDirection(SignInStyleWrapper);
