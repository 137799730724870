import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Input, Button, message } from "antd";
import SignInStyleWrapper from "./signin.style";
import * as API_HELPER from "../../helpers/const";
import axios from "axios";
//import logosWithText from "../../image/EHS_LOGO_TEXT.png";
import * as jwt from "jsonwebtoken";
//var jwt = require("jsonwebtoken");

class SignIn extends Component {
  state = {
    email: "",
    password: ""
  };

  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.props.history.push("/app");
    }
  }

  error = () => {
    message.error("Invalid Credential");
  };

  handleLogin = () => {
    var headers = {
      "Content-Type": "application/json"
    };
    if (this.state.emailErr || this.state.passErr) {
      return;
    }
    var data = {
      email: this.state.email,
      password: this.state.password
    };

    axios
      .post(API_HELPER.API_URL.adminsLogin, data, { headers: headers })
      .then(res => {
        if (res.data.status === "invalid") {
          this.error();
        } else {
          this.setState({
            email: "",
            password: ""
          });
          var payload = jwt.decode(res.data.token);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("admin_id", payload.admin_id);
          localStorage.setItem("role", payload.role);
          localStorage.setItem("first_name", payload.first_name);
          localStorage.setItem("last_name", payload.last_name);

          this.props.history.push("/app");
        }
      })
      .catch(function(error) {
        message.error("Something Went Worng");
      });
  };

  _handleEmail(e) {
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!e.target.value) {
      this.setState({ emailErr: true });
      return;
    } else if (!re.test(String(e.target.value).toLowerCase())) {
      this.setState({ emailErr: true });
      return;
    }

    this.setState({ email: e.target.value, emailErr: false });
  }

  _handlePass(e) {
    if (!e.target.value) {
      this.setState({ passErr: true });
      return;
    }
    this.setState({ password: e.target.value, passErr: false });
  }

  render() {
    return (
      <SignInStyleWrapper className="fomaSignInPage">
        <div className="fomaLoginContentWrapper">
          <div className="fomaLoginContent">
            <div className="fomaLogoWrapper">
              <Link to="/login" className="headingFoma">
                <img style={{ maxHeight: 140 }} src={require("./../../"+process.env.REACT_APP_LOGO_WIDE)} alt="" />
              </Link>
            </div>
            <div className="fomaSignInForm">
              <div className="fomaInputWrapper">
                {this.state.emailErr ? (
                  <span className="err">Invalid Email</span>
                ) : (
                  <span></span>
                )}
                <Input
                  size="large"
                  placeholder="Username"
                  onChange={e => this._handleEmail(e)}
                />
              </div>
              <div className="fomaInputWrapper">
                {this.state.passErr ? (
                  <span className="err">Password Required</span>
                ) : (
                  <span></span>
                )}
                <Input
                  size="large"
                  type="password"
                  placeholder="Password"
                  onChange={e => this._handlePass(e)}
                />
              </div>
              <div className="fomaInputWrapper fomaLeftRightComponent">
                {/* <Checkbox> Remember Me</Checkbox>*/}
                <Button
                  type="primary"
                  className="loginBtn"
                  onClick={this.handleLogin}
                >
                  Login
                </Button>
              </div>

              {/* <div className="fomaCenterComponent fomaHelperWrapper">
                   <Link to="/forgotpassword" className="fomaForgotPass">
                     Forgot Password
                   </Link>
                 </div>
                 */}
            </div>
          </div>
        </div>
      </SignInStyleWrapper>
    );
  }
}

export default SignIn;
