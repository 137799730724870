export default {
  apiUrl: 'http://yoursite.com/api/'
};
const siteConfig = {
  siteName: process.env.REACT_APP_NAME,
  siteIcon: 'ion-flash',
  footerText: process.env.REACT_APP_NAME + ' ©2020'
};

const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault'
};



const Auth0Config = {
  domain: '',
  clientID: '',
  allowedConnections: ['Username-Password-Authentication'],
  rememberLastLogin: true,
  language: 'en',
  closable: true,
  options: {
    auth: {
      autoParseHash: true,
      redirect: true,
      redirectUrl: 'http://localhost:3000/auth0loginCallback'
    },
    languageDictionary: {
      title: process.env.REACT_APP_NAME,
      emailInputPlaceholder: 'foma@gmail.com',
      passwordInputPlaceholder: 'foma'
    },
    theme: {
      labeledSubmitButton: true,
      logo: '',
      primaryColor: '#000',
      authButtons: {
        connectionName: {
          displayName: 'Log In',
          primaryColor: '#b7b7b7',
          foregroundColor: '#000000'
        }
      }
    }
  }
};
export {
  siteConfig,
  themeConfig,
  Auth0Config,
};
