import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import authAction from "../../redux/auth/actions";
import appActions from "../../redux/app/actions";
import Sidebar from "../../components/sidebar/Sidebar";
import Topbar from "../../components/topbar/Topbar";
import Navigation from "../navigation/navigation";
import { siteConfig } from "../../settings";
import { Link } from "react-router-dom";
import AppHolder from "./commonStyle";
import "./global.css";
import { isAdmin, isSuperAdmin } from "../../helpers/utility";

const { Content, Footer } = Layout;
const { logout } = authAction;
const { toggleAll } = appActions;

export class App extends Component {
  constructor(props) {
    super(props);
    if (!localStorage.getItem("token")) {
      this.props.history.push("/login");
    }
  }
  render() {
    const { url } = this.props.match;
    const { pathname } = this.props.location;
    let curmb = pathname.split("/app/");
    // let title = pt.split('/')[1];
    let title = curmb[2] ? curmb[2] : curmb[1];
    title = title ? title.split("/")[0] : "";
    curmb = curmb[1] ? curmb[1].split("/") : "";
    const { height } = this.props;
    const appHeight = window.innerHeight;

    function Greeting(props) {
      // const title= props.title;
      // alert(title);
      if (title) {
        if (isAdmin() || isSuperAdmin()) {
          title = title.replace("admin", "User");
          curmb[0] = curmb[0].replace("admin", "user");
          if (curmb[1]) {
            curmb[1] = curmb[1].replace("Admin", "user");
          }
        }
        return (
          <div className="bread">
            <h4>
              {title} <b id="count"></b>
            </h4>
            <span>
              {curmb[1] ? (
                <Link to={"/app/" + curmb[0]}>{curmb[0]}</Link>
              ) : (
                curmb[0]
              )}
              {curmb[1] ? " > " + curmb[1] : ""}
            </span>
          </div>
        );
      } else {
        return <span></span>;
      }
    }
    return (
      <AppHolder>
        <Layout style={{ height: appHeight }}>
          <Topbar url={url} />
          <Layout style={{ flexDirection: "row", overflowX: "hidden" }}>
            <Sidebar url={url} history={this.props.history} />
            <Layout
              className="fomaContentMainLayout"
              style={{ height: height }}
            >
              <Content
                className="fomaContent"
                style={{
                  padding: "70px 0 0",
                  flexShrink: "0",
                  background: "#f1f3f6",
                  position: "relative"
                }}
              >
                <Greeting titles={title}></Greeting>
                <Navigation url={url} />
              </Content>
              <Footer
                style={{
                  background: "#ffffff",
                  textAlign: "center",
                  borderTop: "1px solid #ededed"
                }}
              >
                {siteConfig.footerText}
              </Footer>
            </Layout>
          </Layout>
        </Layout>
      </AppHolder>
    );
  }
}

export default connect(
  state => ({
    auth: state.Auth,
    height: state.App.height
  }),
  { logout, toggleAll }
)(App);
