import React, { Component } from 'react';

export default class extends Component {

  state = {
    url: '/login',
    checked: false
  }

  componentDidMount() {

    localStorage.previousLocation = this.props.location.pathname;
    if (localStorage.nexPrev !== localStorage.previousLocation) {
      this.setState({
        previousLocation: localStorage.previousLocation
      })
    }

    if (localStorage.previousLocation === "/") {
      localStorage.previousLocation = "/app/";
    }

    if (localStorage.getItem("token")) {
      this.props.history.push(localStorage.previousLocation);
    } else {
      this.props.history.push('/login');
    }

    localStorage.nextPrev = localStorage.previousLocation;
  }

  render() {

    return <span to="/login" />;

  }
}
