const options = [
  {
    key: '',
    label: 'Dashboard',
    leftIcon: 'ion-ios-home-outline',
    disable: false
  },
  {
    key: 'members',
    label: 'Members',
    leftIcon: 'ion-ios-people-outline',
    disable: false
  },
  {
    key: 'transactions',
    label: 'Payments',
    leftIcon: 'image',
    disable: false
  },
  {
    key: 'admin',
    label: "User",
    leftIcon: 'ion-ios-person-outline',
    disable: false
  },
  {
    key: 'reports',
    label: "Reports",
    leftIcon: 'image',
    disable: false
  },
];
export default options;


