import styled from 'styled-components';
import { palette, font } from 'styled-theme';

const DashAppHolder = styled.div`
  font-family: ${font('primary', 0)};

 //  .ant-btn-primary{
 //    background-color:  ${palette('primary', 0)};    border-color: ${palette('primary', 0)};
 //    &:hover{
 //    background-color:  ${palette('primary', 1)};    border-color: ${palette('primary', 1)};

 //    }
 //  }


 //  .fomaSidebar .fomaLogoWrapper{
 //      background-color:  ${palette('primary', 1)};    
 //      a{
 //            font-size: 24px;
 //    font-weight: 400;
 //    line-height: 1.4;
 //    color: #788195;
 //    text-align: center;
 //    text-transform: uppercase;
 //      }
 //  }

 // .fomaSidebar .fomaDashboardMenu{
 //      background-color:  ${palette('primary', 0)};    
 // }

 // a{
 //    color: ${palette('primary', 0)};
 // }

 // .foma-link{
 //    color: ${palette('primary', 0)};
 // }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  input,
  textarea,
  span,
  div,
  img,
  svg {
    &::selection {
      background: ${palette('primary', 0)};
      color: #fff;
    }
  }



  .ant-row:not(.ant-form-item) {
    ${'' /* margin-left: -8px;
    margin-right: -8px; */};
    &:before,
    &:after {
      display: none;
    }
  }

  .ant-row > div {
    padding: 0;
  }



  .fomaLeftRightComponent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
.flter-btn{text-align:right;width:100%;margin-bottom:20px;}
  .fomaCenterComponent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

export default DashAppHolder;
