import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';


var baseURL = "/app";
const routes = [
  {
    path: baseURL,
    component: asyncComponent(() => import('../dashboard/dashboard.js')),
    breadcrumbName: 'Dashboard'
  },
  {
    path: baseURL + '/members',
    component: asyncComponent(() => import('../members/members.js')),
    breadcrumbName: 'Members'
  },
  {
    path: baseURL +  '/members/addmember',
    component: asyncComponent(() => import('../addMember/addmember.js')),
    breadcrumbName: 'Add Members'
  },
  {
    path: baseURL + '/members/attachment/:id',
    component: asyncComponent(() => import('../attachment/attachment.js')),
    breadcrumbName: 'Attachment'
  },

  {
    path: baseURL + '/members/addcontact/:id',
    component: asyncComponent(() => import('../addContact/addContact.js')),
    breadcrumbName: 'Add Contact'
  },

  {
    path: baseURL + '/members/editcontact/:id/:memid',
    component: asyncComponent(() => import('../editContact/editContact.js')),
    breadcrumbName: 'Edit Contact'
  },

  {
    path: baseURL + '/transactions/addtransactions',
    component: asyncComponent(() => import('../addTransactions/addTransactions.js')),
    breadcrumbName: 'Add Transactions'
  },
  {
    path: baseURL + '/members/addpayment/:id',
    component: asyncComponent(() => import('../addPayment/addPayment.js')),
    breadcrumbName: 'Add Payment'
  },
  {
    path: baseURL + '/members/editpayment/:id/:memid',
    component: asyncComponent(() => import('../editPayment/editPayment.js')),
    breadcrumbName: 'Edit Payment'
  },
  {
    path: baseURL + '/members/printLabel',
    component: asyncComponent(() => import('../printLabel/printLabel.js')),
    breadcrumbName: 'Print Label'
  },
  {
    path: baseURL + '/members/memberDetails/:id',
    component: asyncComponent(() => import('../memberDetails/memberDetails.js')),
    breadcrumbName: 'Member Details'
  },
  {
    path: baseURL + '/members/editMemberDetails/:id',
    component: asyncComponent(() => import('../editMemberDetails/editMemberDetails.js')),
    breadcrumbName: 'Edit Member Details'
  },
  {
    path: baseURL + '/admin',
    component: asyncComponent(() => import('../admin-role/adminRole.js')),
    breadcrumbName: 'Admin'
  },
  {
    path: baseURL + '/admin/viewAdmin/:id',
    component: asyncComponent(() => import('../viewAdmin/viewAdmin.js')),
    breadcrumbName: 'View Admin'
  },
  {
    path: baseURL + '/members/updateAdmin/:id',
    component: asyncComponent(() => import('../updateAdmin/updateAdmin.js')),
    breadcrumbName: 'Edit Admin Details'
  },
  {
    path: baseURL + '/admin/addAdmin',
    component: asyncComponent(() => import('../addAdmin/addadmin.js')),
    breadcrumbName: 'Admin'
  },
  {
    path: baseURL + '/transactions',
    component: asyncComponent(() => import('../transactions/transactions.js')),
    breadcrumbName: 'Transactions'
  },
  {
    path: baseURL + '/reports',
    component: asyncComponent(() => import('../reports/reports.js')),
    breadcrumbName: 'Reports'
  },
  {
    path: baseURL + '/admin/profilesetting',
    component: asyncComponent(() => import('../profile/profile.js')),
    breadcrumbName: 'Profile setting'
  }

];

class Navigation extends Component {
  state = {
    bredCrumb: null
  }
  render() {
    const { style } = this.props;
    return (
      <div style={style}>
        {routes.map(singleRoute => {
          const { path, breadcrumbName, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact
              key={singleRoute.path}
              breadcrumbName={breadcrumbName}
              path={singleRoute.path}
              {...otherProps}
            />
          );
        })}
      </div>
    );
  }
}

export default Navigation;
