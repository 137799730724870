import styled from 'styled-components';
import { palette } from 'styled-theme';

const AppHolder = styled.div`
  -webkit-overflow-scrolling: touch;
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 16px;
    cursor: pointer;
    transition: color 0.3s;
  }

.ant-switch-disabled{
    cursor: auto;
    opacity: 0.7;
  *{
      cursor: auto;
  }
  &:after{
      cursor: auto;
  }
}


.headingFoma{
  text-align:"center",
  text-transform:"uppercase"
}


  .formLay .ant-form-item-label{text-align: left!important;}
    .frm_group{display:flex;align-items:center;}
        .frm_group label,.frm_group .ant-select{padding:0 5px;}

      .formLay .ant-form-item{margin-bottom: 14px!important;}


  .trigger:hover {
    color: ${palette('primary', 0)};
  }

  .ant-layout-sider-collapsed .anticon {
    font-size: 16px;
  }

  .ant-layout-sider-collapsed .nav-text {
    display: none;
  }

  .ant-layout {
    background: ${palette('secondary', 1)};

    &.fomaContentMainLayout {
      overflow: auto;
      overflow-x: hidden;
      @media only screen and (min-width: 768px) and (max-width: 1220px) {
        width: calc(100% - 80px);
        flex-shrink: 0;
      }

      @media only screen and (max-width: 767px) {
        width: 100%;
        flex-shrink: 0;
      }
    }
  }

  .fomaLayoutContent {
    width: 100%;
    padding: 35px;
    background-color: #ffffff;
    border: 1px solid ${palette('border', 0)};
    height: 100%;
  }

  .fomaLayout {
    width: calc(100% - 240px);
    flex-shrink: 0;
    overflow-x: hidden !important;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }

    @media only screen and (min-width: 768px) and (max-width: 1220px) {
      width: calc(100% - 80px);
      width: 100%;
    }
  }

  .ant-layout-footer {
    font-size: 13px;
    @media (max-width: 767px) {
      padding: 10px 20px;
    }
  }

  ${'' /* button {
    border-radius: 0;
  } */};
`;

export default AppHolder;
