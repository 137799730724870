import React from 'react';
import ReactDOM from 'react-dom';
import DashApp from './dashApp';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import './css/foma.css'
import 'antd/dist/antd.less';

ReactDOM.render(<DashApp />, document.getElementById('root'));
console.log(`${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
