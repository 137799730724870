import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import appActions from "../../redux/app/actions";
import TopbarUser from "./topbarUser";
import TopbarWrapper from "./topbar.style";

const { Header } = Layout;
const { toggleCollapsed } = appActions;

class Topbar extends Component {

  render() {
    const { toggleCollapsed, locale } = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: "#FFF",
      position: "fixed",
      width: "100%",
      height: 70
    };
    if (localStorage.token) {
      return (
        <TopbarWrapper>
          <Header
            style={styling}
            className={collapsed ? "fomaTopbar collapsed" : "fomaTopbar"}>
            <div className="fomaLeft">
              <button
                className={collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"}
                style={{ color: "#000" }}
                onClick={toggleCollapsed}
              />
            </div>
            <ul className="fomaRight">
              <li onClick={() => this.setState({ selectedItem: "user" })} className="fomauser">
                <TopbarUser url={this.props.url} locale={locale} />
              </li>
            </ul>
          </Header>
        </TopbarWrapper>
      );
    } else {
      return (
        <div>No Data Found...</div>
      )
    }
  }
}

export default connect(
  state => ({
    ...state.App,
  }),
  { toggleCollapsed }
)(Topbar);
